<template>
  <v-card-text>
    <v-card>
      <v-card-text>
        <v-switch v-model="refreshToggle"
          ><template v-slot:label>
            Enable Auto-Refreshing
            <v-progress-circular
              :indeterminate="isFetchingRmsAudits"
              :value="0"
              size="24"
              class="ml-2"
              :class="['ml-2', { 'd-none': !isFetchingRmsAudits }]"
            ></v-progress-circular></template
        ></v-switch>
        <v-data-table
          :headers="headers"
          :footer-props="footerProps"
          :items="rmsAudits"
          :loading="isLoading"
          :server-items-length="rmsAuditsCount"
          :options.sync="options"
        >
          <template v-slot:item.provider="{ item }">
            {{ item.attributes.rmsProviderName }}
          </template>
          <template v-slot:item.created="{ item }">
            {{ formatDatetime(item.attributes.created) }}
          </template>
          <template v-slot:item.response="{ item }">
            Message: {{ item.attributes.responseMessage }}<br />
            Body: {{ item.attributes.responseBody }}<br />
          </template>
          <template v-slot:item.resend="{ item }">
            <v-btn
              :loading="item.loading"
              :disabled="item.loading"
              color="primary"
              @click="handleResendRmsAuditRecord(item)"
              >Resend</v-btn
            >
          </template>
          <template v-slot:no-data>
            <no-data-alert />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-card-text>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import NoDataAlert from "@admin/components/NoDataAlert.vue";
import _ from "lodash";

export default {
  name: "RmsAuditsIndex",
  data() {
    return {
      isLoading: false,
      isFetchingRmsAudits: false,
      headers: [
        {
          text: "Provider",
          value: "provider",
        },
        {
          text: "Created",
          value: "created",
        },
        {
          text: "response",
          value: "response",
          sortable: false,
        },
        {
          text: "Resend",
          value: "resend",
          sortable: false,
        },
      ],
      options: {
        page: 1,
        itemsPerPage: 20,
        sortBy: [],
        sortDesc: [],
      },
      refreshToggle: false,
      ticker: undefined,
      autoRefreshOff: false,
    };
  },
  computed: {
    ...mapState("rms_providers", ["rmsAudits", "rmsAuditsCount"]),
    ...mapState(["footerProps"]),
    ...mapGetters(["acceptRoles"]),
    showLanguagesAddButton() {
      return this.acceptRoles(["Admin", "Super User"]);
    },
    pageAndSortData() {
      const data = (({ page, itemsPerPage, sortBy }) => ({
        page,
        limit: itemsPerPage,
        sortBy,
      }))(this.options);

      if (this.options.sortBy.length) {
        data.sortBy = [];
        for (let i = 0; i < this.options.sortBy.length; i++) {
          const sortField = this.options.sortBy[i];
          const desc = this.options.sortDesc[i] === true ? "-" : "";
          data.sortBy.push(desc + sortField);
        }
      }

      return data;
    },
  },
  watch: {
    pageAndSortData: {
      async handler(currentValue, oldValue) {
        if (_.isEqual(currentValue, oldValue)) return;

        this.isLoading = true;

        await this.getRmsAudits(currentValue);
        this.isLoading = false;
      },
      deep: true,
    },
    //we want to watch the refreshToggle, and if true then we want to refetch the rms audits every 10 seconds
    refreshToggle: {
      async handler(isToggled) {
        if (isToggled && this.ticker === undefined) {
          await this.startTicker();
        } else {
          this.stopTicker();
        }
      },
    },
  },
  components: {
    NoDataAlert,
  },
  methods: {
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setTitleObject",
    ]),
    ...mapActions("rms_providers", ["getRmsAudits", "resendRmsAuditRecord"]),
    async startTicker() {
      //lets save a cookie as 0 stating that we want to auto refresh when user access page again
      this.setCookie("rms_audit_auto_refresh_off", 0);
      this.ticker = setInterval(async () => {
        this.isFetchingRmsAudits = true;
        await this.getRmsAudits();
        this.isFetchingRmsAudits = false;
      }, 5000);
    },
    stopTicker() {
      //lets save a cookie stating that we want the auto refresh to be turned off when user access page again
      this.setCookie("rms_audit_auto_refresh_off", 1);
      clearInterval(this.ticker);
      this.ticker = undefined;
    },
    handleResendRmsAuditRecord(auditRecord) {
      //remove row from UI
      this.rmsAudits.splice(this.rmsAudits.indexOf(auditRecord), 1);
      //resend Audit record to RMS Provider
      this.resendRmsAuditRecord(auditRecord.attributes.contactId);
    },
    async setCookie(name, value, days) {
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
  },
  async beforeMount() {
    if (!this.rmsAudits.length) {
      this.isLoading = true;
      await this.getRmsAudits();
      this.isLoading = false;
    }

    this.setBreadcrumbs();
    this.setTitleObject("RMS Audits");
  },
  async mounted() {
    //check if autofresh was disabled by user and was saved in cookies
    const disableAutoRefresh = this.getCookie("rms_audit_auto_refresh_off");

    if ((await disableAutoRefresh) === "1") {
      this.refreshToggle = false;
    } else {
      this.refreshToggle = true;
    }
  },
  destroyed() {
    this.clearActionButtons();
    this.clearTitleObject();
    clearInterval(this.ticker);
  },
};
</script>

<style scoped></style>
